.flex-container {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  width: 22em;
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid #f26921;
  border-radius: 8px;
}

.four-text-container {
  text-align: right;
  width: 15em;
}

.four-text-container p,
.four-text-container h4 {
  margin-top: -1em;
}

.missing-icon {
  width: 8em;
}

@media only screen and (max-width: 600px) {
  .four-text-container p {
    font-size: 0.8em;
  }
}
