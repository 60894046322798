.work-container {
  width: 99%;
  height: 96%;
  margin: 0 auto;
  padding-top: 1.25%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.work-card {
  width: 24%;
  height: 30%;
  background-color: #fff;
  border: 2px solid #f26921;
  border-radius: 8px;
  overflow: hidden;
}

.thumb {
  width: 24%;
  height: 24vh;
}

.thumbnail {
  width: 425%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-1%, -50%);
}

.feature-gallery {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.feature-gallery .thumb {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
}
.feature-gallery:hover .thumb {
  opacity: 1;
  transition: all 0.35s;
  -moz-transform: scale(1.13);
  -webkit-transform: scale(1.13);
  -ms-transform: scale(1.13);
  transform: scale(1.13);
}
.fg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all.3s;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor-zoom {
  cursor: pointer;
}

.feature-gallery:hover .fg-overlay {
  background-color: rgba(64, 64, 64, 0.9);
  opacity: 1;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.fg-overlay h2 {
  text-align: center;
  font-size: 1.8em;
  font-weight: 400;
  color: #f26922;
  opacity: 0;
  -moz-transform: scale(0.1);
  -ms-transform: scale(0.1);
  -o-transform: scale(0.1);
  -webkit-transform: scale(0.1);
  transform: scale(0.1);
  transition: all 0.35s;
}
.feature-gallery:hover .fg-overlay h2,
.feature-gallery:hover .fg-overlay p {
  opacity: 1;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.fg-overlay:before {
  opacity: 0;
  border: 1px solid #fff;
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  content: "";
  display: block;
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  transition: all 0.35s;
}
.feature-gallery:hover .fg-overlay:before {
  opacity: 1;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

@media only screen and (min-width: 1900px) {
  .fg-overlay:before {
    opacity: 0;
    position: absolute;
  }
}

@media only screen and (min-width: 2500px) {
  .fg-overlay h2 {
    font-size: 2.6em;
  }

  .thumb {
    width: 27%;
    height: 27vh;
  }

  .thumbnail {
    width: 370%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    transform: translate(0.1%, -55%);
  }
}

@media only screen and (max-width: 1200px) {
  .work-container {
    padding-top: 0.7em;
  }
  .thumbnail {
    width: 600%;
    top: 50%;
    transform: translate(-15%, -50%);
  }
}

@media only screen and (max-width: 900px) {
  .work-container {
    height: calc(86vh - 2.5em);
  }

  .work-card {
    width: 31%;
    height: 19vh;
  }

  .thumbnail {
    width: 560%;
    top: 40%;
    transform: translate(-12%, -50%);
  }

  .fg-overlay h2 {
    font-size: 1.75em;
  }
}

@media only screen and (max-width: 600px) {
  .work-container {
    height: 76vh;
  }

  .work-card {
    width: 30%;
    height: 17vh;
    margin-top: 0.4em;
  }

  .thumbnail {
    width: 755%;
    top: 35%;
    transform: translate(-23%, -50%);
  }

  .fg-overlay h2 {
    font-size: 0.95em;
  }
}
