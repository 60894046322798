.what-do-container {
  text-align: center;
}

.headline {
  font-size: 2em;
  font-weight: 400;
  padding-top: 6vh;
  font-family: trajan;
}

.do-page-container {
  width: 95%;
  max-width: 90em;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
}

@media only screen and (min-width: 1900px) {
  .what-do-container {
    position: relative;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
  }

  .headline {
    margin: 0 auto;
    font-size: 2.7em;
  }

  .do-page-container {
    margin-top: 3.5em;
  }

  .what-do-container img {
    width: 10.5em;
  }
}

@media only screen and (min-width: 2300px) {
  .what-do-container {
    position: relative;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
  }

  .do-page-container {
    width: 95%;
    height: 80%;
    max-width: 200em;
  }

  .headline {
    font-size: 3.8em;
  }

  .what-do-container img {
    width: 13em;
  }
}

@media only screen and (max-width: 1200px) {
  .do-page-container {
    width: 99%;
  }
}

@media only screen and (max-width: 900px) {
  .headline {
    font-size: 1.25;
    padding-top: 3vh;
  }
  .do-page-container {
    display: block;
  }
  .what-body-container {
    display: flex;
    margin-top: -1em;
  }
}

@media only screen and (max-width: 600px) {
  .what-do-container {
    display: block;
    overflow: hidden;
  }

  .headline {
    margin-top: 0vh;
    margin-bottom: 0.8em;
    line-height: 1em;
  }

  .do-page-container {
    margin-top: -2.5vh;
    overflow-y: scroll;
    height: auto;
    max-height: 69vh;
  }

  .about-container {
    max-height: 64.5vh;
  }
}
