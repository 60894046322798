.contact-page-container {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: wrap;
  justify-content: space-evenly;
}

.information-container {
  width: 75%;
  height: 80%;
  margin-top: 4.5%;
  border: 3px solid #f26921;
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
}

.information-v-align {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact-container {
  width: 20%;
  height: 11.5em;
  margin-top: 4.5%;
  border: 3px solid #f26921;
  border-radius: 8px;
  background-color: #fff;
}

.contact-headline {
  font-size: 2.4em;
  margin-top: 0;
  font-family: trajan;
  font-weight: 400;
}

.contact-body {
  font-size: 1.2em;
  padding: 0 5em;
}

.schedule-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 30em;
  max-height: 10em;
  margin: 3.5em auto;
}

.calendar-container {
  display: static;
}

.month {
  font-family: trajan;
  color: rgb(59, 67, 75);
  position: relative;
  top: -7.3em;
}

.date {
  font-family: trajan;
  font-size: 3em;
  color: rgb(59, 67, 75);
  position: relative;
  top: -3.4em;
}

.scedule-appointment-icon {
  width: 10em;
}

.schedule-button {
  background-color: #f26921;
  width: 10em;
  height: 3em;
  border-radius: 8px;
  border: 4px solid rgb(59, 67, 75);
  color: #fff;
  font-size: 1.1em;
  padding-top: 0.8em;
  margin-top: 2.5em;
}

.schedule-button:hover {
  cursor: pointer;
  background-color: rgb(59, 67, 75);
}

.contact-icon {
  width: 2.5em;
  margin-left: 0.9em;
  margin-top: 0.1em;
}

.contact-button {
  display: flex;
  flex-direction: row;
  margin: 1em auto;
  width: 12em;
  height: 4em;
  background-color: #f26921;
  border-radius: 8px;
  color: #fff;
  border: 2px solid rgb(59, 67, 75);
  justify-content: space-between;
}

.contact-button:hover {
  background-color: rgb(59, 67, 75);
  cursor: pointer;
}

.contact-button-text-container {
  display: block;
  margin-top: -0.72em;
}

.contact-button-text,
.contact-button-detail {
  font-size: 1.2em;
  margin-right: 1.4em;
  margin-bottom: -0.9em;
  text-align: right;
}

.email-text {
  font-size: 0.63em;
  padding-top: 1em;
  padding-right: 1.15em;
}

@media only screen and (min-width: 2300px) {
  .contact-headline {
    font-size: 3.5em;
  }

  .contact-body {
    font-size: 1.5em;
  }

  .scedule-appointment-icon {
    width: 15em;
  }

  .calendar-container {
    height: 15em;
  }

  .month {
    font-size: 2.4em;
    top: -5.2em;
  }

  .date {
    font-size: 4em;
    top: -4.2em;
  }

  .schedule-button {
    height: 3.2em;
    font-size: 1.6em;
    padding-top: 0.8em;
    margin-top: 2.5em;
  }

  .schedule-container {
    max-width: 45em;
    margin: 6em auto 10em;
  }

  .contact-container {
    height: 16em;
  }

  .contact-icon {
    width: 3.6em;
    margin-left: 1.2em;
  }

  .contact-button {
    width: 16em;
    height: 6em;
    border: 4px solid rgb(59, 67, 75);
  }

  .contact-button-text-container {
    display: block;
    margin-top: -0.72em;
  }

  .contact-button-text,
  .contact-button-detail {
    font-size: 1.6em;
    margin-right: 0.7em;
  }

  .email-text {
    font-size: 0.9em;
    padding-top: 1.2em;
    padding-right: 0.4em;
  }
}

@media only screen and (max-width: 1200px) {
  .information-container {
    margin-top: 1.5%;
    height: 94%;
  }
  .contact-headline {
    font-size: 2.1em;
  }

  .contact-container {
    margin-top: 1.5%;
  }
}

@media only screen and (max-width: 900px) {
  .contact-page-container {
    display: block;
    flex-direction: none;
    flex-wrap: nowrap;
    padding-top: 3%;
  }

  .information-container {
    margin-top: 0;
    margin: 0 auto;
    height: 80%;
    width: 93%;
  }

  .contact-container {
    width: 70%;
    height: 12%;
    display: flex;
    flex-wrap: row;
    margin: 2% auto;
    justify-content: space-evenly;
  }

  .contact-button {
    margin: 0;
    margin-top: 1.2em;
  }

  .contact-headline {
    font-size: 2.7em;
  }
}

@media only screen and (max-width: 600px) {
  .contact-headline {
    font-size: 1.7em;
  }

  .contact-body {
    font-size: 0.9em;
    padding: 0 1.8em;
  }

  .information-container {
    height: 70%;
    margin-top: 3%;
  }

  .schedule-container {
    margin: 1.25em 0 -2em;
  }

  .month {
    font-family: trajan;
    color: rgb(59, 67, 75);
    position: relative;
    top: -6em;
  }

  .date {
    font-family: trajan;
    font-size: 2.2em;
    color: rgb(59, 67, 75);
    position: relative;
    top: -3.75em;
  }

  .scedule-appointment-icon {
    width: 7.5em;
  }

  .schedule-button {
    background-color: #f26921;
    width: 9em;
    height: 3.5em;
    border-radius: 8px;
    border: 4px solid rgb(59, 67, 75);
    color: #fff;
    font-size: 0.95em;
    padding-top: 0.8em;
    margin-top: 1.8em;
  }

  .contact-button-detail {
    display: none;
  }

  .contact-container {
    width: 93%;
    height: 16%;
    margin-top: 3.5%;
  }

  .contact-button {
    width: 10em;
    font-size: 0.9em;
    margin-top: 1.3em;
  }

  .contact-button-text {
    padding: 0;
    margin: 0;
    margin-right: 0.85em;
    margin-top: 1.7em;
  }
}
