.container {
  margin: 0 auto;
  height: 12em;
  color: #f26921;
  font-family: "trajan", serif;
  margin-top: 34.5vh;
  margin-bottom: calc(100vh - (35vh + 13em));
}

.logo-box {
  text-align: center;
  margin: 0 auto;
  display: flex;
  max-width: 45em;
  justify-content: center;
}

.logo {
  width: 8em;
}

.font-container {
  margin-left: 2em;
  margin-top: -0.5em;
}

.pre-font {
  font-size: 1.8em;
}

.logo-font {
  font-size: 3.8em;
  margin-top: -0.4em;
}

.line {
  border: 1px solid #f26921;
  margin-top: -3.5em;
}

@media only screen and (min-width: 2300px) {
  .container {
    margin: 0 auto;
    height: 12em;
    color: #f26921;
    margin-top: 38vh;
    margin-bottom: calc(100vh - (39vh + 13em));
  }

  .logo {
    width: 10.6em;
  }

  .logo-box {
    max-width: 49.5em;
  }

  .pre-font {
    font-size: 2.2em;
  }

  .logo-font {
    font-size: 4.3em;
    margin-top: -0.45em;
  }

  .line {
    border: 1px solid #f26921;
    margin-top: -3.3em;
  }

  .tag-line {
    font-size: 1.15em;
    margin-top: 1.2em;
  }
}

@media only screen and (max-width: 900px) {
  .container {
    margin-left: 3em;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    margin: 25vh auto 0;
    height: 65vh;
    width: 100%;
  }

  .logo-box {
    display: inline;
    max-width: 100%;
    margin: 0 auto;
  }

  .logo {
    width: 7em;
    margin-left: calc(50% - 3.5em);
  }

  .font-container {
    margin: -1em auto 0;
  }

  .logo-font {
    font-size: 2.6em;
    margin-top: -0.65em;
  }

  .line {
    width: 90%;
    margin-top: -2.25em;
  }

  .tag-line {
    font-size: 0.825em;
    margin-top: 1.4em;
  }
}
