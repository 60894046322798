.menu-container {
  width: 98%;
  max-width: 115em;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.nav-items {
  display: flex;
  float: right;
}

.nav-button {
  color: white;
  margin: 1.25em 0.8em 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.25em;
  border: none;
  background: none;
  padding-bottom: 7px;
}

.nav-button:hover {
  color: #f26921;
  cursor: pointer;
  padding-bottom: 4px;
  border-bottom: #f26921 solid 3px;
}

.active-nav-button {
  color: #f26921;
  margin: 1.25em 0.8em 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.25em;
  border: none;
  background: none;
  padding-bottom: 4px;
  border-bottom: #f26921 solid 3px;
}

.active-nav-button:hover {
  cursor: pointer;
}

.logo-round {
  width: 4em;
  margin-left: 0.5em;
}

@media only screen and (min-width: 2300px) {
  .nav-button,
  .active-nav-button {
    font-size: 1.8em;
    margin: em 0.5em 1em 0.5em;
  }

  .logo-round {
    width: 5em;
    margin: 0.8em 0 -0.8em 1.5em;
  }
}

@media only screen and (max-width: 1200px) {
  .nav-items {
    margin-right: 1.25em;
  }
  .nav-button {
    margin: 1.25em 0.15em 0;
  }

  .active-nav-button {
    margin: 1.25em 0.25em 0;
  }

  .logo-round {
    margin-left: 1.5em;
  }
}

@media only screen and (max-width: 900px) {
  .nav-items {
    margin-right: 0.75em;
  }
  .nav-button {
    margin: 1.25em 0 0;
  }

  .active-nav-button {
    margin: 1.25em 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .nav-items {
    justify-content: space-between;
    right: 10px;
    width: calc(100% - 7em);
    max-width: 18em;
  }

  .nav-button {
    font-size: 1.25em;
    margin-top: 0.5em;
    padding: 0;
    padding-bottom: 3px;
  }

  .active-nav-button {
    font-size: 1.25em;
    margin-top: 0.5em;
    padding: 0;
    padding-bottom: 3px;
    border-bottom: none;
  }

  .nav-button:hover {
    font-size: 1.25em;
    margin-top: 0.5em;
    padding: 0;
    padding-bottom: 3px;
    color: #fff;
    border: none;
  }

  .logo-round {
    width: 3.5em;
    margin-left: 0.7em;
  }

  .menu-container {
    position: -webkit-sticky;
    position: sticky;
    top: 1px;
    z-index: 10;
    background-color: #0e0d0d;
    padding-bottom: 8px;
  }
}
