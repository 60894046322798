@font-face {
  font-family: Trajan;
  src: url(./fonts/Trajan\ Pro\ Regular.ttf) format(TrueType);
}

@font-face {
  font-family: Futura;
  src: url(./fonts/Futura.ttc) format(TrueType Collection);
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #0e0d0d;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%232a2a2a' fill-opacity='0.14' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.splash-screen {
  height: 66vh;
}

.orange-bar {
  width: 100%;
  height: 2.3vh;
  margin: 0 auto;
  background-color: #f26921;
}

.jump-button {
  width: 7vh;
  max-width: 5em;
  position: relative;
  left: 94%;
  bottom: 15vh;
  cursor: pointer;
}

.main-content-container {
  margin-top: 2vh;
}

.page-container {
  width: 98%;
  max-width: 115em;
  height: calc(100vh - 10.8em);
  background-color: rgba(256, 256, 256, 0.6);
  margin: 2.8vh auto 0;
  border-radius: 0.1em;
  font-family: "Futura, Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
}

@media only screen and (max-width: 1200px) {
  .splash-screen {
    height: 60vh;
  }

  .orange-bar {
    position: relative;
    bottom: 6.8vh;
  }

  .jump-button {
    width: 8vh;
    bottom: 22vh;
    left: 91%;
  }
}

@media only screen and (max-width: 900px) {
  .splash-screen {
    height: 60.5vh;
  }
  .jump-button {
    width: 6.5vh;
    left: 88%;
    bottom: 16vh;
  }

  .orange-bar {
    height: 1.4vh;
    bottom: 4vh;
  }
}

@media only screen and (max-width: 600px) {
  .splash-screen {
    height: 65vh;
  }

  .orange-bar {
    height: 2.5vh;
    bottom: 3.8vh;
  }

  .jump-button {
    bottom: 16vh;
    left: 81%;
  }
}
