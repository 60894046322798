.card-container {
  width: 27%;
  max-width: 30em;
  background-color: #fff;
  height: 60vh;
  max-height: 33em;
  border: 3px solid #f26921;
  border-radius: 8px;
}

.card-header {
  font-family: trajan;
  margin-top: 1.5em;
}

.card-icon {
  width: 10em;
}

.card-second-heading {
  color: #f26921;
  margin-bottom: -1em;
  font-size: 1.2em;
}

.card-description {
  padding: 0 1.5em;
}

@media only screen and (min-width: 1900px) {
  .card-header {
    font-family: trajan;
    font-weight: 400;
    font-size: 1.8em;
    margin-top: 1em;
  }

  .card-description {
    font-size: 1.1em;
    padding: 0.5em 1.15em;
  }

  .card-container {
    width: 28%;
  }

  .card-icon {
    width: 10.5em;
  }

  .card-second-heading {
    margin-bottom: 0em;
    padding-bottom: 0.5em;
  }
}

@media only screen and (min-width: 2300px) {
  .card-container {
    width: 30%;
    margin-top: 0.7em;
    max-width: 40em;
    max-height: 40em;
  }

  .card-header {
    font-size: 2.4em;
  }

  .card-icon {
    width: 13em;
  }

  .card-description {
    font-size: 1.4em;
    margin-top: 1em;
    padding: 0.1em 2em 0;
  }
}

@media only screen and (max-width: 1200px) {
  .card-container {
    width: 32%;
  }

  .card-header {
    font-size: 1.3em;
  }

  .card-description {
    margin-top: 0.35em;
    padding: 0.1em 0.7em 0;
  }
}

@media only screen and (max-width: 900px) {
  .card-container {
    width: 90%;
    max-width: 55em;
    margin: 1em auto;
    padding: 1.5em;
    height: auto;
  }

  .card-body-container {
    display: flex;
    margin-bottom: -0.5em;
  }

  .card-header {
    margin: 0em;
    margin-top: 0.5em;
    margin-left: 5.5em;
    font-size: 1.75em;
    font-weight: normal;
  }

  .card-icon-container {
    margin-top: -1.25em;
  }

  .card-description-container {
    height: 9.25em;
    margin-right: -0.5em;
  }
  .card-description {
    font-size: 1em;
    padding: 0;
    padding-left: 0.5em;
    margin: 0 0.75em;
    position: relative;
    top: 45%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 600px) {
  .card-body-container {
    display: block;
    height: auto;
  }

  .card-icon {
    width: 8em;
    margin-top: 0.5em;
  }

  .card-description-container {
    margin: -0.5em 0 1.25em;
    height: auto;
  }

  .card-description {
    font-size: 1em;
    padding: 0 0.5em;
    position: static;
    top: 0%;
    -webkit-transform: translateY(-0%);
    -ms-transform: translateY(-0%);
    transform: translateY(-0%);
  }

  .card-header {
    margin: 0.9em auto;
  }

  .card-icon-container {
    margin-bottom: 1.5em;
  }

  .card-icon {
    width: 45%;
  }

  .card-container {
    max-width: 85%;
    padding: 0.1em;
  }
}
