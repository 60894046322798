.overflow-hidden {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 75%);
  backdrop-filter: blur(4px);
}

.portfolio-viewer-container {
  width: 95%;
  max-width: 130em;
  height: 72vh;
  margin: 0 auto;
  margin-top: 1vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-button {
  color: #fff;
  background-color: rgb(45, 44, 44);
  position: relative;
  left: calc(100% - 1.25em);
  top: -1.25em;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50px;
  border: solid 2px #f26921;
  z-index: 10;
}

.close-button:hover {
  background-color: #f26921;
  cursor: pointer;
}

.gallery-container {
  width: 100%;
  height: 100%;
  position: relative;
  top: -4vh;
  z-index: 0;
  background-color: #fff;
  text-align: center;
  border-radius: 0.5em;
  border: solid 2px #f26921;
}

.gallery-title-container {
  height: 10%;
  border-bottom: #f26921 3px solid;
}

.gallery-title-container h2 {
  font-family: trajan;
  font-size: 2.5em;
  font-weight: 400;
  color: #f26921;
}

.gallery-body {
  display: flex;
  height: 83%;
}

.main-image-container {
  display: flex;
  width: 80%;
}

.rendered-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-image-1 {
  margin: 0.75% -25% 0 0;
  max-width: 100%;
  max-height: 96%;
}

.gallery-image-2 {
  margin: 0.75% auto 0;
  max-width: 48%;
  padding: 0 1%;
  max-height: 96%;
}

.gallery-image-3 {
  margin: 0.75% auto 0;
  max-width: 31%;
  padding: 0 1%;
  max-height: 96%;
}

.gallery-image-4 {
  margin: 0.75% auto 0;
  max-width: 23%;
  padding: 0 1%;
  max-height: 96%;
}

.description-container {
  width: 20%;
  max-height: 95vh;
  margin-right: 1.5em;
  max-height: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.work-description {
  padding: 1em;
  border: #f26921 1.5px solid;
  border-radius: 5px;
}

@media only screen and (min-width: 1900px) {
  .work-description {
    font-size: 1.2em;
  }

  .close-button {
    top: -1.5em;
    width: 3em;
    height: 3em;
    border-radius: 50px;
    border: solid 2px #f26921;
    z-index: 10;
  }
}

@media only screen and (min-width: 2300px) {
  .close-button {
    top: -2.2em;
    width: 3.5em;
    height: 3.5em;
    border-radius: 50px;
    border: solid 2px #f26921;
    z-index: 10;
    left: calc(100% - 1.5em);
  }

  .work-description {
    font-size: 1.45em;
  }

  .gallery-title-container h2 {
    font-family: trajan;
    font-size: 3em;
    font-weight: 400;
    color: #f26921;
  }
}

@media only screen and (max-width: 1200px) {
  .portfolio-viewer-container {
    margin-top: 1.5vh;
    width: 95%;
    height: 84%;
  }

  .description-container {
    max-height: 75vh;
    width: 28%;
  }

  .gallery-image-1 {
    margin: 0.75% 0 0 10%;
  }

  .close-button {
    left: calc(100% - 1.5em);
    width: 3.25em;
    height: 3.25em;
    font-weight: bold;
  }
}

@media only screen and (max-width: 900px) {
  .portfolio-viewer-container {
    height: 80%;
    width: 93%;
  }

  .close-button {
    left: calc(100% - 1.8em);
    width: 3.6em;
    height: 3.6em;
  }

  .gallery-container {
    top: -4.5%;
    right: 0.5%;
  }

  .gallery-title-container {
    height: 8%;
  }

  .gallery-title-container h2 {
    font-size: 2em;
    padding-top: 0.2em;
  }

  .gallery-body {
    display: block;
    height: 86%;
    margin-top: 2%;
  }

  .main-image-container {
    width: 98%;
    height: 75%;
    margin: 0 auto;
  }

  .gallery-image-1 {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }

  .gallery-image-2 {
    max-width: 48%;
    max-height: 100%;
  }

  .description-container {
    width: 97%;
    margin: 2% auto 0;
    height: 23%;
  }

  .work-description {
    vertical-align: middle;
  }
}

@media only screen and (max-width: 600px) {
  .modal {
    z-index: 15;
  }
  .portfolio-viewer-container {
    height: 88%;
    width: 90%;
    display: block;
    left: 48.5%;
  }

  .close-button {
    left: calc(100% - 2em);
    width: 3.8em;
    height: 3.8em;
    top: -0.5em;
  }

  .gallery-container {
    margin: 0 auto;
  }

  .gallery-title-container {
    height: 7.5%;
  }

  .gallery-title-container h2 {
    font-size: 1.2em;
    margin-top: 0.8em;
    padding-top: 0.5em;
    line-height: 1em;
  }

  .gallery-body {
    height: 89%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-evenly;
  }

  .description-container {
    width: 95%;
    margin: 0 auto;
    padding: 0em;
    height: auto;
    background-color: none;
    border: #f26921 1.5px solid;
    border-radius: 5px;
  }

  .work-description {
    height: auto;
    font-size: 0.9em;
    padding: 0 0.5em;
    margin: 0.5em auto;
    border: none;
  }

  .main-image-container {
    width: 93%;
    max-height: 68%;
    display: block;
    margin: 0 auto;
  }

  .rendered-image-container {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
  }
  .gallery-image-1 {
    margin: 0 auto;
  }

  .gallery-image-2 {
    max-width: 95%;
    max-height: 48%;
    margin: 0 auto;
  }

  .gallery-image-3 {
    max-width: 95%;
    max-height: 90%;
    margin: 0 auto 3%;
  }

  .gallery-image-4 {
    max-width: 95%;
    max-height: 90%;
    margin: 1.5% auto;
  }

  .link-break {
    display: none;
  }

  .gallery-title-container h2 {
    font-size: 1.3em;
  }
}
