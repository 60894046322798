.footer-container {
  width: 100%;
  background-color: #f26921;
  height: 1.7em;
  position: relative;
  margin-top: 1.25em;
  text-align: center;
}

.footer-text {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 0.9em;
  color: white;
  margin: 0 auto;
  padding-top: 0.3em;
}

@media only screen and (min-width: 1800px) {
  .footer-text {
    font-family: sans-serif;
    font-size: 1em;
    padding-top: 0.25em;
  }
}
